<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card>
      <v-toolbar dark flat color="#1C3144">
        <v-toolbar-title>Failure Reasons</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6" style="max-height:200px; overflow:auto;">
        <ul v-for="reason in failureReason" :key="reason">
          <li style="color:red;">{{reason}}</li>
        </ul>
      </v-container>
      <v-toolbar flat color="#fafafa">
        <v-spacer />
        <v-btn color="#0c67a5" text @click="show=false">Okay</v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'FailureReason',
  data () {
    return {}
  },
  props: [
    'value',
    'failureReason'
  ],
  computed: {
    show: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }

}
</script>
